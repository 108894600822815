import React from 'react';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import EmbedContactForm from '../../components/Contact/EmbedContactForm';
import useGoogleAnalyticsPageview from '../../hooks/useGoogleAnalyticsPageview';
import ImageViewerModal from '../../components/Contact/ImageViewerModal/ImageViewerModal';

const FOLLOW_UP_FORM_BUDGET_KEY = 'b8q39';
const FOLLOW_UP_CONTACT_FORM_ID = 'tintashstage2';

function FollowUp({location}) {
  useGoogleAnalyticsPageview({title: 'Follow Up Form'});
  const [modalImgURL, setModalImgURL] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.origin !== `https://${FOLLOW_UP_CONTACT_FORM_ID}.paperform.co`) {
        // something from an unknown domain, let's ignore it
        return;
      }
      if (event.data?.startsWith('IMG:')) {
        const imgURL = event.data?.replace('IMG:', '');
        setModalImgURL(imgURL);
        setIsModalOpen(true);
      }
    });
  }, []);

  const submissionHandler = React.useCallback(e => {
    e.detail.data.forEach(fieldObj => {
      if (fieldObj.key === FOLLOW_UP_FORM_BUDGET_KEY) {
        const budgetEvent = {
          category: 'FollowUpForm',
          action: 'Submitted Form 2',
          label: 'Client Budget Score',
          value: 0,
        };
        try {
          budgetEvent.value = parseInt(fieldObj.value.match(/[0-9]+/)[0]);
        } catch {
          budgetEvent.value = 0;
        }
        ReactGA.event(budgetEvent);
      }
    });
  }, []);

  return (
    <div className="py-4 px-1">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Follow Up Form | Tintash</title>
      </Helmet>
      <ImageViewerModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        imgURL={modalImgURL}
        imgAlt="Reference: Typical Budgets for Successful Product Journeys"
      />
      <EmbedContactForm
        paperformId={FOLLOW_UP_CONTACT_FORM_ID}
        searchParams={location.search}
        paperformSubmissionHandler={submissionHandler}
      />
    </div>
  );
}

export default FollowUp;
